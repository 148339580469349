.product_ditail_page {
    width: 100%;
    padding: 0 20px;
}

.container-product_ditail {
    width: 100%;
}

/* .container-product_ditail h2 {
    margin: 40px 0;
    padding-bottom: 20px;
    border-bottom: 2px solid orange;
} */

.product_ditail_top {
    width: 100%;
    padding: 10px;
    display: flex;
    margin-top: 60px;
    justify-content: space-between;
}



.baner_room {
    width: 100%;
    height: 50vh;
    border-radius: 20px;
    display: flex;
    align-items: center;

    text-align: center;

}

.baner_room h1 {
    font-size: 70px;
    color: #000000;
}

.product_ditail_bottom {
    width: 100%;
}

.product_ditail_top_img {
    width: 65%;
    height: 80vh;
    border-radius: 20px;
    /* border: #000000 2px solid; */
    overflow: hidden;
    display: flex;
    justify-content: center;

    align-items: center;
}

.product_ditail_top_img img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.product_ditail_top_content {
    width: 30%;
    padding: 0 20px;
}

.product_ditail_top_content_name {
    /* border-bottom: 1px solid rgb(0, 0, 0); */
    font-size: 35px;
    font-weight: bold;
}

.product_ditail_top_content_price {
    /* border-bottom: 1px solid rgb(0, 0, 0); */
    font-size: 25px;
    color: rgb(0, 0, 0);
    padding: 15px 0;
}

.rooms_item_content_icon_ditail {
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.rooms_item_content_icon_ditail p {
    margin: 0;
    margin-right: 25px;
    font-size: 15px;
}

.rooms_item_content_icon_ditail h6 {
    margin: 0;
    margin-right: 25px;
    font-size: 18px;
    font-weight: bold;
}

.rooms_item_content_icon_ditail .icon {
    font-size: 30px;
    margin-right: 20px;
}

/* .product_ditail_top_content_code {
    border-bottom: 1px solid rgb(0, 0, 0);
    padding: 5px 0;
}

.product_ditail_top_content_origin {
    border-bottom: 1px solid rgb(0, 0, 0);
    padding: 5px 0;
} */

.product_ditail_top_content_add_cart {
    padding: 10px 15px;
    margin: 20px 0;
    border: none;
    background: rgb(255, 255, 255);
    border-radius: 5px;
    color: #000;
    position: relative;
    overflow: hidden;
    /* width: 250px; */
    height: 50px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.product_ditail_top_content_add_cart_before {
    
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-radius: 10px;
    transition: 0.5s;
    background: #000;
    color: #fff;
}

.product_ditail_top_content_add_cart:hover .product_ditail_top_content_add_cart_before {
    top: 0%;
}

.product_ditail_top_content_add_cart:hover {
    transform: translateY(-5px);
}

.product_ditail_top_content_short_description h4 {
    margin: 20px 0;
}

.product_ditail_bottom {
    padding: 50px 0;
}

.product_ditail_bottom p {
    padding: 20px 0;
    font-size: 20px;
}


.product_ditail_room_amenities {
    padding: 0 0 50px 0;
    width: 100%;
}

.product_ditail_room_amenities h2 {
    margin: 40px 0;
}

.product_ditail_room_amenities ul {
    display: flex;
    width: 100%;
    /* justify-content: space-between; */
    margin: 0;
    flex-wrap: wrap;
    padding: 0;

}

.product_ditail_room_amenities ul li {
    width: 23%;
    /* border: 0.5px solid #000; */
    border-radius: 20px;
    display: flex;
    padding: 20px 20px;
    margin: 15px 20px 15px 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.product_ditail_room_amenities ul li:hover {
    transform: translateY(-5px);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.product_ditail_room_amenities ul li p {
    margin: 0;
    font-size: 35px;
    margin-right: 20px;
}

.product_ditail_room_amenities ul li span {
    margin: 0;
    padding: 0;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product_ditail_bottom_list {
    width: 100%;
    margin-bottom: 50px;
}

.product_ditail_bottomList {
    border-bottom: 1px solid rgb(0, 0, 0);
    padding: 30px 0;
    margin-bottom: 50px;

}

@media only screen and (max-width: 768px) {
    .product_ditail_top {
        width: 100%;
        padding: 10px;
        display: flex;
        flex-direction: column;
    }

    .product_ditail_top_img {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 50vh;
        margin-bottom: 30px;
    }

    .product_ditail_top_img img {
        width: 100%;

    }

    .product_ditail_top_content {
        width: 100%;
        padding: 0 20px;
    }

    .product_ditail_bottom_list .card_product {
        width: 100%;
    }

    .product_ditail_room_amenities ul li {
        width: 43%;
    }

}
.slider {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    overflow: hidden; 
}

.slide-container {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.slide {
    flex: 0 0 100%;
    width: 100%;
}

.slide.active {
    transform: translateX(-100%); /* Chuyển slide sang trái */
}

img {
    width: 100%;
    height: auto;
}

.arrow {
    position: absolute;
    font-size: 2rem;
    cursor: pointer;
}

.left {
    left: 0;
}

.right {
    right: 0;
}
