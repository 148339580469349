.content_giatui{
    width: 100%;
    /* height: 50vh; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.content_giatui img{
    width: 60%;
    height: auto;
}
.content_giatui table{
    margin: 20px 0;
    text-align: center;
}
.content_giatui table th{
    padding: 10px;
    border: 1px solid #000;
    
}
.content_giatui table td{
    padding: 10px;
    border: 1px solid #000;
    
}