main {
    width: 100%;

}

.home_slider {
    width: 100%;
    height: 90vh;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* border-radius: 20px; */
   
    overflow: hidden;
}

.home_slider img {
    width: 100%;
    /* height: 90vh; */
    object-fit: cover;
    position: relative;
}

.info_item {
    position: absolute;
    top: 30%;
}

.con_content_slider {
    /* width: 100%; */
    color: #fff;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.con_content_slider h2 {
    font-size: 60px;
    font-weight: 700;

}

.content_slider .con_content_slider p {
    line-height: 30px;
}

.content_slider .con_content_slider h4 {
    color: #eb6424;
}

.content_slider .con_content_slider a {
    width: 180px;
    height: 50px;
    text-decoration: none;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(45deg, #eb6424, #fbba72);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 25px 0 25px 25px;
    margin-top: 20px;
}

.con_content_slider button{
    margin-top: 20px;
    background: #fff;
    border: none;
    /* border-radius: 30px; */
    padding: 10px 15px;
    color: #000;
}
.con_content_slider button h3{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    margin: 0;
}
.con_content_slider button:hover{
    border-radius: 30px;
    /* background: #000;
    color: #fff; */
}

.content_slider a:hover {
    background: linear-gradient(-45deg, #eb6424, #fbba72);
}

.info_item .learn_more {
    width: 180px;
    height: 50px;
    background: linear-gradient(45deg, #eb6424, #fbba72);
    color: #fff;
    font-size: 2 0px;
    border: none;
    border-radius: 25px 0 25px 25px;
}

.info_item .learn_more:hover {
    background: linear-gradient(-45deg, #eb6424, #fbba72);
}

.why_choose_utilities {
    width: 100%;
    /* background: #000000; */
    margin: 30px 0;
    position: relative;
    color: #edecec;
}

.con_why_choose_utilities {
    width: 100%;
    padding: 20px;
}

.con_why_choose_utilities .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.con_why_choose_utilities .con_why_choose_title_utilities {
    width: 100%;
    /* height: 40vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    
}
.con_why_choose_utilities .con_why_choose_title_utilities h1 {
    font-weight: 700;
    font-size: 42px;

}
.con_why_choose_utilities .con_why_choose_title_utilities h6 {
    width: 60%;
    height: 10vh;
    text-align: center;
    
}

.con_why_choose_utilities .about_us{
    /* background: #ffffff; */
    margin: 10px 5px;
    padding: 5px;
    color: #000;
}

.con_why_choose_utilities .about_us .why_choose{
    /* background: #000000; */
    height: 220px;
    margin: 5px;
    padding: 15px;
    color: #ffffff;
    border-radius: 10px;
    border: 1px solid #fff;
}

.con_why_choose_utilities .about_us .why_choose h3{
    font-size: 25px;
    font-weight: 600;
    margin: 10px 0;
}
.con_why_choose_utilities .about_us .why_choose img{
    width: 30%;
}



/* 
.utilities {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 80%;

}

.utilities .utilitie {
    text-align: center;
    line-height: 30px;
    margin-bottom: 20px;

}

.utilities .utilitie h6 {
    font-size: 18px;
    color: #3B566E;
    margin-bottom: 10px;
}

.utilities .utilitie .reason_icon {
    width: 100px;
    height: 100px;
    background: none;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    font-size: 60px;
} */
.products_home{
    padding: 30px 0;
}
.products_home .product{
    width: 100%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin: 40px 0;
}
.products_home .product img{
    width: 40%;
}
.products_home .product .product_content{
    width: 50%;
    display: block;
    padding: 20px;
}
.products_home .product .product_content a{
    text-decoration: none;
}

.products_home .product .product_content h2{
    font-size: 24px;
    font-weight: 600;
}

.barner_filter{
    width: 100%;
    height: 400px;
    display: flex;
    padding: 20px 0;
}
.barner_filter .barner_filter_content{
    width: 50%;
    padding: 30px;
    display: flex;
    flex-direction: column;
}
.barner_filter .barner_filter_content h2{
    font-size: 24px;
    font-weight: 600;
}



.classify_rooms {
    width: 100%;
    /* height: 40vh; */
    position: relative;
    padding: 20px 0;
    border-bottom: 1px solid #000;
}

.con_classify_rooms {
    width: 100%;
    top: -10%;
    left: 0;
    z-index: 100;
}

.con_classify_rooms .con_classify_rooms_title {
    width: 100%;
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 700;
}
.con_classify_rooms .con_classify_rooms_title h1{
    font-weight: 700;
}

.customer_us_title{
    width: 100%;
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
}


.customer_us_title h1{
    font-weight: 700;
    font-size: 42px;
}

.con_classify_rooms .con_classify_rooms_viewall {
    width: 100%;
    height: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: 1px solid #000;
    font-size: 18px;

}

.con_classify_rooms_viewall a {
    text-decoration: none;
    color: #000;
    padding: 0;
    margin: 0;
}

.con_classify_rooms .con_classify_rooms_viewall p {
    margin: 0;
    width: 100px;
    border-bottom: 2px solid #000;
    cursor: pointer;
}

.con_classify_rooms h1 {
    font-size: 40px;
    /* font-family: 'Cormorant Garamond', serif; */
    font-family: 'Montserrat'serif;
    margin-bottom: 15px;
}

.classify_rooms_items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

}

.classify_rooms_items .rooms_item {
    width: 300px;
    /* height: 450px; */
    background: #fff;
    border-radius: 15px;
    text-align: start;
    line-height: 30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-bottom: 20px;
    overflow: hidden;

}

.rooms_items_homepage {
    margin: 100px 0;
}

.classify_rooms_items .rooms_item .rooms_item_content {
    padding: 5px 20px;
}

.rooms_item_content_icon {
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.rooms_item_content_icon p {
    margin: 0;
    margin-right: 20px;
    font-size: 16px;
}

.rooms_item_content_icon .icon {
    font-size: 30px;
    margin-right: 10px;
}

.xemthem {
    position: relative;
    width: 95px;
}

.xemthem::before {
    position: absolute;
    width: 0;
    height: 2px;
    background: rgb(0, 0, 0);
    content: '';
    top: 100%;
    left: 0;
    transition: 1s;
}


.classify_rooms_items .rooms_item:hover {
    scale: 1.05;
}

.classify_rooms_items .rooms_item:hover .xemthem::before {
    width: 90px;
    
}
.div_xemthem p{
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;
    color: rgb(44, 17, 134);
}
.div_xemthem:hover .xemthem::before {
    width: 90px;
    background: rgb(44, 17, 134);
}


.classify_rooms_items .rooms_item .rooms_item_img {
    width: 100%;
    height: 250px;
    background: #edecec;
    margin: 0 auto;
    margin-bottom: 20px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

}

.classify_rooms_items .rooms_item .rooms_item_img img {
    width: 130%;
}

.classify_rooms_items .rooms_item a {
    text-decoration: none;
    color: #000;
}


/* 
.rooms {
    width: 100%;
    height: 40vh;
    position: relative;
    padding: 40px 0;
}

.con_rooms {
    width: 100%;
    top: -10%;
    left: 0;
    z-index: 100;

}

.con_rooms .container {
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.rooms_items h1 {
    font-size: 50px;
    font-family: 'Cormorant Garamond', serif;
    font-family: 'Montserrat' serif;
    margin-bottom: 50px;
}

.rooms_items h5 {
    text-decoration: 1px solid #000;
}

.rooms_items {
    display: block;
    text-align: center;
    width: 75%;
}

.con_rooms_items {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

}

.con_rooms_items .rooms_item {
    width: 300px;
    height: 250px;
    background: #fff;
    border-radius: 10px;
    text-align: start;
    line-height: 30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;

}

.con_rooms_items .rooms_item .rooms_item_img {
    width: 100%;
}

.con_rooms_items .rooms_item .rooms_item_img img {
    width: 130%;
}

.con_rooms_items .rooms_item .rooms_item_content {
    padding: 5px 20px;
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    color: #000000;
    transition: 0.5s;
    background: #f2f2f3;
}

.con_rooms_items .rooms_item:hover .rooms_item_content {
    top: 85%;
} */

.rooms_item_content_icon {
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.rooms_item_content_icon p {
    margin: 0;
    margin-right: 20px;
    font-size: 16px;
}

.rooms_item_content_icon .icon {
    font-size: 30px;
    margin-right: 10px;
}

.rooms_item_content .xemthem {
    position: relative;
    width: 110px;
}

.rooms_item_content .xemthem::before {
    position: absolute;
    width: 0;
    height: 2px;
    background: rgb(0, 0, 0);
    content: '';
    top: 100%;
    left: 0;
    transition: 1s;
}

.rooms_item_content .rooms_item:hover {
    scale: 1.05;
}

.rooms_item_content .rooms_item:hover .xemthem::before {
    width: 90px;
}

.con_rooms .advertisement {
    width: 25%;
    /* height: 800px; */
    background: #ffffff;
    /* padding: 30px 0; */
    border-radius: 20px;
    overflow: hidden;
}

.con_rooms .advertisement .advertisement_booknow {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.home_customer_typical {
    width: 100%;
    padding: 80px 20px;
    border-top: 1px solid orange;
    background: #eeeeee;
}

.home_customer_typical_title {
    width: 100%;
    text-align: center;
    margin-bottom: 60px;
    justify-content: center;
    align-items: center;
    display: flex;

}

.home_customer_typical_slider {
    width: 100%;
    overflow: hidden;
}

.home_customer_typical_slider img {
    width: 100%;
}

.container_logo_doitac {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.home_customer_typical_slider .slider-images {
    display: flex;
    width: 12%;
    border: none;
    justify-content: space-between;

}






/* Cat anh */
.customer_us img{
    object-fit: cover;
    width:300px;
    height:200px;
    margin-bottom: 15px;
}








@media only screen and (max-width: 1200px) {
    .solusion_other_con_card {
        width: 200px;
        /* height: 300px; */
    }

    .home_blog_card {
        width: 300px;
    }

    .home_slider img {
        width: 100%;
        height: auto;
    }
}

@media only screen and (max-width: 992px) {
    .solusion_home .solusion_home_hospital {
        /* padding: 50px 0; */
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .solusion_home .solusion_home_info {
        width: 100%;
    }

    .con_solusion_home {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .solusion_home_img {
        margin: 30px 0;
    }

    .solusion_other_con {
        flex-wrap: wrap;
    }

    .solusion_other_con_card {
        width: 270px;
        height: 390px;

    }

    .container_home_blog_card {
        flex-wrap: wrap;

    }

    .con_content_slider {
        width: 80%;
    }
}

@media only screen and (max-width: 992px) {
    .slider {
        height: 60vh;
    }
}

@media only screen and (max-width: 768px) {
    .rooms_items {
        display: block;
        text-align: center;
        width: 50%;
    }

    .con_rooms .advertisement {
        width: 50%;
    }

    .classify_rooms_items {
        justify-content: center;
    }

    .classify_rooms_items .rooms_item {
        width: 80%;
    }

    .con_content_slider {
        width: 100%;
        top: 40%;
    }

    .con_content_slider h2 {
        font-size: 40px;
    }
}

@media only screen and (max-width: 481px) {

    .con_slider img {
        width: 100%;
    }

    .con_slider {

        height: 90vh;
    }

    .content_slider {

        top: 35%;
    }

    .con_content_slider {
        top: 30%;
    }

    .con_content_slider p {
        display: none;

    }
}

.container_product_cart {
    text-align: center;
}