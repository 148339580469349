.content_eatapec a{
    text-decoration: none;
}
.Container_eatother{
    margin: 30px 0;
    color: #000;
    cursor: pointer;
}
.Container_eatother:hover{
    scale: 1.05;

}
.Container_eatother h3{
margin: 10px 0;
}
.Container_eatother p{
    text-decoration: initial;
}
.Container_eatother img{
width: 100%;
height: 250px;
}