.OtherServices .Container .Row {
    width: 100%;
/* height: 30vh; */
margin: 50px 0;
background: red;

}
.OtherServices a{
    color: #000;
    text-decoration: none;
}
.Container_otherservices{
    width: 100%;
    /* height: 300px; */
    /* background: red; */
    margin: 50px 0;
    padding: 10px;
    border-radius: 15px;
    /* border: 1px solid #000; */
}
.Container_otherservices img{
margin-bottom: 20px;
width: 100%;
height: auto;
/* height: 300px; */
}
.Container_otherservices p{
    text-decoration: underline;

}
.Container_otherservices:hover{
    scale: 1.1;
    cursor: pointer;
}