.baner_product {
    width: 100%;
    height: 50vh;
    /* border-radius: 20px; */
    display: flex;
    align-items: center;
    text-align: center;
}

.baner_product h1 {
    font-size: 54px;
    color: #ffffff;
font-weight: 700;
}

.button_vew{
    padding: 10px 15px;
    border: none;
    margin: 0;
    margin-right: 10px;
    font-weight: 500;
   
}
.button_vew:hover{
    background: #000;
    color: #ffffff;
}

.button_contact{
    padding: 10px 15px;
    border: none;
    margin: 0;
    margin-right: 10px;
    font-weight: 500;
}
.button_contact:hover{
    background: red;
    color: #ffffff;
}

.breadcrumb{
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 15px;
    width: 100%;
    background: #edf2f3;
    margin-top: 20px;
}
.breadcrumb a{
    text-decoration: none;
    font-weight: 600;
    color: #000000;
}


.product_list_air_drying .product img{
width: 30%;
}