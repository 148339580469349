
.content_happyhouse img{
    width: 100%;
    margin: 20px 0;
}
.content_happyhouse h1{
    margin: 30px 0;
}

@media only screen and (max-width: 768px) {
    .baner_contact h1 {
        font-size: 40px;
    }
}