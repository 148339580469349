.card_product {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    position: relative;
    overflow: hidden;
    border-radius: 40px 0 40px 40px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-bottom: 20px;
}

.card_product:hover {
    transform: translateY(-10px);
}

.ing_product {
    width: 100%;
    /* height: 250px; */
}

.ing_product img {
    width: 100%;
}

.quick_view {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
}

.content_cart_product {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: 30px;
}

.product_name {
    font: 500;
    color: #000;
    text-decoration: none;
}

.product_name:hover {
    color: orange;
}

.product_code {
    font-size: 15px;
}

.add_cart {
    padding: 5px 15px;
    border: none;
    border-radius: 10px;
    margin: 10px;
    border-radius: 20px 0 20px 20px;
    background: linear-gradient(90deg, #eb6424, #fbba72);
    color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.add_cart:hover {
    background: linear-gradient(-90deg, #eb6424, #fbba72);

}

.product_label {
    position: absolute;
    top: 0;
    right: 0;
    background: orange;
    padding: 0 5px;
}






.rooms_card {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    /* width: 45%; */
}

.rooms_card .rooms_card_item {
    width: 100%;
    height: 230px;
    background: #fff;
    border-radius: 15px;
    text-align: start;
    line-height: 30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
}

.rooms_card .rooms_card_item img {
    width: 100%;
}

.rooms_card .rooms_card_item .rooms_card_item_content {
    padding: 5px 20px;
}

.rooms_card_item_content {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    color: #ffffff;
    text-decoration: none;
    margin: 0;
    opacity: 1;
    /* background: #f4f3f3; */
    background-color: rgba(0, 0, 0, 0.3); /* Màu đen với độ mờ 50% */
    transition: 0.5s;
    font-size: 20px;
    display: flex;
    align-items: center;
    padding: 10px;
}

.rooms_card_item_content p {
    margin: 0;
    padding: 0;
    text-decoration: inherit;
    font-size: 15px;
}
.rooms_card_item_content span{
    font-size: 18px;
}
.rooms_card .rooms_card_item:hover{
    scale: 1.1;
}
.rooms_card .rooms_card_item:hover .rooms_card_item_content {
    opacity: 1;
    transform: translateY(-100%);

}

/* .rooms_card_item_content .rooms_card_xemthem::before {
    position: absolute;
    width: 0;
    height: 2px;
    background: rgb(245, 66, 66);
    content: '';
    top: 100%;
    left: 0;
    transition: 1s;
} */


@media only screen and (max-width: 768px) {
    .card_product {
        width: 100%;
        margin: 10px 0;
        padding: 10px 5px;
    }

    .card_product h5 {
        font-size: 1rem;
    }

    .add_cart {
        padding: 5px;
        margin: 0;
        display: none;
    }

    .card_product:hover .add_cart {
        display: block;
    }

    .card_product:hover .product_price {
        display: none;
    }

    .product_list .col-12 {
        flex: 0 0 auto;
        width: 100%;
        display: flex;
        justify-content: center;
    }
}