.content_bunbo img{
    margin: 20px 0;
}
.content_bunbo h1{
    margin: 30px 0;
}
.content_bunbo table{
    width: 100%;
    margin: 20px 0;
    text-align: center;
}
.content_bunbo table tr th{
    border: 1px solid #000;
}
.content_bunbo table tr td{
    border: 1px solid #000;
}
