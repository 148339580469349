.footer {
    background: #181818;
    padding-top: 30px;
    width: 100%;
   /* border-radius: 20px; */
    margin-top: 20px;
    line-height: 30px;

}
.footer .container{
    /* background: #000000; */
    border-radius: 20px;
    color: #fff;
    /* height: 50vh; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer_top {
    display: flex;
    justify-content: space-between;
    align-items: start;
    text-align: left;
    flex-wrap: wrap;
    padding: 50px 0;

}

.footer_left {
    width: 25%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

}

.footer_left img {
    width: 50%;
    margin-bottom: 20px;
}

.footer_center {
    width: 30%;
    display: flex;
    justify-content: center;
    
}

.footer ul {
    margin: 0;
    padding: 0;
}

.footer ul li {
    line-height: 30px;
    padding-left: 5px;
    margin: 0;
}

/* .footer .helpful ul li:hover {
    border-left: 2px solid #000;
} */

.footer .contact_us {
    /* margin-top: 10px; */
    width: 25%;
}

.footer .contact_us ul li {
    line-height: 30px;
}
.footer .contact_us  p{
   line-height: 30px;
   margin: 0;
}

.footer .support ul li:hover {
    border-left: 2px solid #000;
}

.footer ul li a {
    text-decoration: none;
    color: #000;
    font-size: 15px;
}


.footer_bot {
    width: 100%;
    text-align: center;
   
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #ffffff;
}

.footer_bot p {
    margin: 0;
    padding: 0;
}
@media only screen and (max-width: 992px){
 .container {
    display: block;
    justify-content: space-between;}

    .footer_center {
        width: 40%;
        display: flex;
        justify-content: start;
        margin: 20px 0;}
}
@media only screen and (max-width: 768px) {

    .footer .container {
        display: block;


    }

    .footer_left {
        width: 100%;
        margin: 0;
    }

    .footer_center {
        width: 100%;
    }

    .footer .contact_us {
        width: 100%;
    }

}

@media only screen and (max-width: 481px) {
    .footer_top { 
        padding: 35px 0;
    }
}