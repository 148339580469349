.top-to-btm {
    position: relative;

}

.icon-position-ontop {
    position: fixed;
    bottom: 40px;
    right: 25px;
    z-index: 2000;
    background-color: #551B54;
    /* border: 2px solid #fff; */
    border-radius: 50%;
    font-size: 30px;
    height: 50px;
    width: 50px;
    color: #fff;
    cursor: pointer;
    animation: movebtn 3s ease-in-out infinite;
    transition: all .5s ease-in-out;
}

.icon-position-ontop:hover {
    animation: none;
    background: #fff;
    color: #551B54;
    border: 2px solid #551B54;
}

@keyframes movebtn {
    0% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(20px);
    }

    50% {
        transform: translateY(0px);
    }

    75% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0px);
    }
}

.call-buton .cc-calto-action-ripple {
    z-index: 99999;
    position: fixed;
    right: 2rem;
    top: 7rem;
    /* background: #e2e2e2; */
    /* background: #ec8b00; */
    /* width: 12rem; */
    /* height: 7rem; */
    /* padding: 1rem; */
    border-radius: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #ffffff;
    -webkit-animation: cc-calto-action-ripple 0.6s linear infinite;
    animation: cc-calto-action-ripple 0.6s linear infinite;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    /* flex-direction: column; */
    justify-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
    padding: 10px;
}
.call-buton .cc-calto-action-ripple button{
    width: 120px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #000;
    background: #fff;
}
@media only screen and (max-width: 481px) {
    .call-buton .cc-calto-action-ripple {
       
        width: 2rem;
        height: 2rem;
    }
    .icon-position-ontop {
       
        width: 2rem;
        height: 2rem;
    }
}


.call-buton .cc-calto-action-ripple i {
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
    font-size: 2.2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
}

.call-buton .cc-calto-action-ripple:hover i {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}

@-webkit-keyframes cc-calto-action-ripple {
    0% {
        -webkit-box-shadow: 0 4px 10px rgba(236, 139, 0, 0.2), 0 0 0 0 rgba(236, 139, 0, 0.2), 0 0 0 5px rgba(236, 139, 0, 0.2), 0 0 0 10px rgba(236, 139, 0, 0.2);
        box-shadow: 0 4px 10px rgba(236, 139, 0, 0.2), 0 0 0 0 rgba(236, 139, 0, 0.2), 0 0 0 5px rgba(236, 139, 0, 0.2), 0 0 0 10px rgba(236, 139, 0, 0.2);
    }

    100% {
        -webkit-box-shadow: 0 4px 10px rgba(236, 139, 0, 0.2), 0 0 0 5px rgba(236, 139, 0, 0.2), 0 0 0 10px rgba(236, 139, 0, 0.2), 0 0 0 20px rgba(236, 139, 0, 0);
        box-shadow: 0 4px 10px rgba(236, 139, 0, 0.2), 0 0 0 5px rgba(236, 139, 0, 0.2), 0 0 0 10px rgba(236, 139, 0, 0.2), 0 0 0 20px rgba(236, 139, 0, 0);
    }
}

@keyframes cc-calto-action-ripple {
    0% {
        -webkit-box-shadow: 0 4px 10px rgba(236, 139, 0, 0.2), 0 0 0 0 rgba(236, 139, 0, 0.2), 0 0 0 5px rgba(236, 139, 0, 0.2), 0 0 0 10px rgba(236, 139, 0, 0.2);
        box-shadow: 0 4px 10px rgba(236, 139, 0, 0.2), 0 0 0 0 rgba(236, 139, 0, 0.2), 0 0 0 5px rgba(236, 139, 0, 0.2), 0 0 0 10px rgba(236, 139, 0, 0.2);
    }

    100% {
        -webkit-box-shadow: 0 4px 10px rgba(236, 139, 0, 0.2), 0 0 0 5px rgba(236, 139, 0, 0.2), 0 0 0 10px rgba(236, 139, 0, 0.2), 0 0 0 20px rgba(236, 139, 0, 0);
        box-shadow: 0 4px 10px rgba(236, 139, 0, 0.2), 0 0 0 5px rgba(236, 139, 0, 0.2), 0 0 0 10px rgba(236, 139, 0, 0.2), 0 0 0 20px rgba(236, 139, 0, 0);
    }
}

span.num {
    /* position: absolute; */
    color: #000000;
    /* left: 0%;
    top: 50%; */
    font-size: 20px;
    /* transform: translateY(-50%); */
    text-decoration: none;
    opacity: 1;
    transition: 0.5s;
}
/* .call-buton .cc-calto-action-ripple:hover span.num{
    opacity: 1;
    left: 130%;
} */