.containerBooking {
  width: 100%;
  height: auto;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 20px;
  position: relative;
  overflow: hidden;
  background: #fff;
}

.checkroom {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: start;

}

.checkroom .checkIn {
  width: 35%;
}

.checkroom .checkOut {
  width: 35%;
}

.containerBooking h1 {
  margin: 0;
}

.containerBooking .checkroom .inputDay {
  width: 100%;
  border-radius: 5px;
  border: none;
  box-shadow: rgba(29, 29, 47, 0.2) 0px 7px 29px 0px;

}

.containerBooking h4 {
  margin: 10px 0 10px 0;
}

.containerBooking input {
  padding: 15px;
}

.react-calendar {
  border-radius: 10px;
  scale: 0.8;
  z-index: 999;
}

.containerSelection {
  margin: 30px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.containerSelection_sel {
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
}

.containerSelection_sel input {
  width: 150px;
  border-radius: 10px;
  /* border: 1px solid #000; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: none;
}

.containerSelection button {
  width: 40px;
  height: 40px;
  /* border-radius: 50%; */
  border: none;
  font-size: 20px;
  cursor: pointer;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  border: none;
  /* background: #ececec; */
  background: none;
}

.selection {
  width: 50px;
  margin: 0 10px;
}

.button_booknow {
  width: 150px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #000;
  margin-top: 20px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  color: #000;
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.containerInfoGuests {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
  left: 100%;
  background: rgb(255, 255, 255);
  transition: 0.5s;
  padding: 50px 10px;
  margin: 50px 0;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #000;
  border-radius: 20px;
}

.containerInfoGuests.active {
  left: 0;
  opacity: 1;
  position: relative;
}

.containerInfoGuests p {
  margin: 0;
  line-height: 25px;
}

.containerInfoGuests h1 {
  margin-bottom: 30px;
}

.form_booking_input {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.form_booking_input .form_group {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form_booking_input .form_group input {
  width: 80%;
  border-radius: 10px;
  /* border: 1px solid #000; */
  border: none;
  margin: 10px 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.form_booking_input .form_group textarea {
  width: 80%;
  height: 70px;
  padding: 10px;
  border-radius: 10px;
  border: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-bottom: 15px;
}

.form_booking_input table {
  width: 80%;
  margin: 50px 0;

}

.form_booking_input table tr {
  border: 1px solid #000;

}

.form_booking_input table p {
  margin: 10px;
}

.form_booking_input .error-message {
  color: red;
  font-size: 20px;
}

.form_booking_input .button_booking {
  width: 40%;
  height: 50px;
  border-radius: 10px;
  /* border: 1px solid #000; */
  border: none;
  font-size: 20px;
  padding: 0;
  color: #fff;
  background: green;
  cursor: pointer;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.form_booking_input .back {
  width: 40%;
  height: 50px;
  border-radius: 10px;
  border: none;
  /* margin-right: 15px; */
  margin: 20px 0;
  /* background: rgb(168, 52, 17); */
  border: 1px solid #000;
  color: #000000;
  font-size: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: pointer;
}

@media only screen and (max-width: 992px) {
  .containerSelection {
    flex-direction: column;
  }
  .checkroom {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
      flex-direction: column;
  }
  
  .checkroom .checkIn {
    width: 80%;
  }
  
  .checkroom .checkOut {
    width: 80%;
  }
}