* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    /* font-family: 'Nunito Sans', sans-serif;
    font-family: 'Cormorant Garamond', serif; */
    font-family: 'Montserrat', serif;
}

.vav {
    width: 100%;
    height: 12vh;
}
/* 
.top_header {
    width: 100%;
    height: 40px;
    background: #3b566e;
    flex-wrap: unset;
}

.top_header_content {
    display: flex;
    color: #fff;
    align-items: center;
}

.top_header_content p{
    display: flex;
    color: #fff;
    align-items: center;
    margin-left: 10px;
} */

.header {
    width: 100%;
    position: fixed;
    z-index: 10;
    height: 80px;
    /* font-family: 'Jost', sans-serif; */
    color: #000;
}

.navbar {
    padding: 5px;
    height: 10vh;
    background: none;
    z-index: 100;
    display: flex;
    flex-wrap: unset;
    transition: background-color 0.3s ease;
}


.navbar.setbackgroud {
    background: rgb(255, 255, 255);
    height: 13vh;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}


.header_left {
    width: 10%;
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-right: 20px;
}

.header_left a {
    width: 100%;

}

.header_left img {
    width: 60%;


}

.header_right {
    width: 90%;
    display: flex;
    justify-content: right;
    align-items: center;
}

#basic-navbar-nav {
    width: 50%;
}

.menu {

    width: 70%;

}

.menu ul {
    display: flex;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    width: 100%;
}



.menu ul li {
    position: relative;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu ul li a {
    color: #3b566e;
    width: 100%;
    text-align: center;

}

.menu ul li h3 {
    font-size: 15px;
    color: #000;
    margin: 0;
    font-weight: 700;
}

.menu>ul>li::before {
    position: absolute;
    width: 0;
    height: 2px;
    background: rgb(79, 79, 79);
    content: '';
    top: 90%;
    left: 0;
    transition: 1s;
}

.menu ul li:hover::before {
    width: 100%;
}

.menu .menu_child {
    display: none;
    position: absolute;
    top: 70%;
    opacity: 0;
    left: 0%;
    white-space: nowrap;
    width: 300px;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 0.4s;
}

.menu .menu_child a {
    text-align: left;
    text-decoration: none;
}

.menu .menu_child li {
    height: 40px;
    display: flex;
    justify-content: left;
    padding-left: 10px;
    background: #f8f6f6;
    border-bottom: 2px solid #eff1f4;
    font-weight: 500;
}

.menu li:hover .menu_child {
    display: block;
    top: 100%;
    opacity: 1;
}

.menu .menu_child li:hover {
    border-left: 2px solid rgb(205, 95, 27);
    background: #eaeaea;
}

.menu .menu_child .menu_child-child {
    position: absolute;
    display: none;
    top: 0;
    left: 100%;
    background: rgb(255, 255, 255);
    border-radius: 5px;
    overflow: hidden;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.menu .menu_child li:hover .menu_child-child {
    display: block;
    z-index: 100;
}

.contactFacebook .Facebook {
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin: 0;
    color: #000;
    width: 150px;
    height: 50px;
    text-decoration: none;
    border: 1px solid #000;
}

.contactFacebook .phone {
    /* margin-right: 10px;
    color: #000;
    text-decoration: none;
    font-size: 18px;
    border: 1px solid #000;
    padding: 10px; */

    padding: 10px;
    border: none;
    border-radius: 5px;

    color: #000;
    width: 150px;
    height: 50px;
    text-decoration: initial;
    /* border: 1px solid #000; */
}

.contactFacebook .phoneIcon {
    margin-right: 15px;
}

.header_right_icon {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 15%;
}

.nav_icon {
    display: none;
    font-size: 20px;
}

.iconFacebook {
    display: none;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #000;
}

.iconFacebook {
    display: none;
}

.linkmenu {
    display: none;

}

.linkmenuhappyhouse {
    border: 1px solid #000;
    border-radius: 5px;
    padding: 10px 10px;
    background: none;
    text-decoration: none;
    color: #000;
    margin-left: 10px;
}

@media only screen and (max-width: 992px) {
    .container {
        display: flex;
        justify-content: space-between;
    }

    .menu {
        display: none;
        width: 10%;
        display: flex;
        position: absolute;
        top: 100%;
        left: -100%;
        background: #eff1f4;
        width: 100%;
        height: 100vh;
        padding: 20px;
        transition: 0.5s;
    }

    .menu>ul>li::before {
        display: none;
    }

    .menu.activemenu {
        top: 100%;
        left: 0;
        padding: 30px 0 0 30px;
    }

    .menu ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .menu ul li {
        height: auto;
        padding: 10px 0;
        display: block;
    }

    .menu ul li a {
        text-align: left;
    }

    .menu .menu_child {
        display: none;
        position: unset;
        transform: translateY(-100%);
        opacity: 0;
        left: 100%;
        transition: 1s;
        margin-top: 10px;
        margin-left: 10px;
    }

    .menu li:hover .menu_child {
        display: block;
        transform: translateY(0%);
        opacity: 1;
    }

    .menu .menu_child ul {
        margin-left: 10px;
        margin-top: 10px;
    }

    .menu .menu_child li {
        margin: 0;
        height: auto;
        display: block;
    }

    .menu .menu_child .menu_child-child {
        position: unset;
    }

    .header_left {
        width: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
    }

    .header_left img {
        width: 100%;
    }

    .header_right {
        width: 15%;
    }

    .header_right_icon {
        width: 100%;
    }

    .nav_icon {
        display: flex;
        width: 20%;
        justify-content: left;
        align-items: start;
        cursor: pointer;
        margin: 0;
        padding: 0;
    }

    .nav_icon1 {
        margin: 0;
        padding: 0;


    }

    .nav_icon2 {
        margin: 0 4px;
        padding: 0;

    }

    .nav_icon3 {
        margin: 0;
        padding: 0;

    }

    .Facebook {
        display: none;
    }

    .iconFacebook {
        display: flex;
    }

    .contactFacebook {
        display: flex;
    }

    .contactFacebook .phone {
        width: 50px;
    }

    .contactFacebook .phone .numberPhone {
        display: none;
    }

    .contactFacebook .phone .phoneIcon {
        display: none;
        font-size: 30px;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #000;
        border-radius: 50%;
        border: 1px solid #000;
    }
}

@media only screen and (max-width: 768px) {
    .header_left {
        width: 25%;
    }

    .contactFacebook {
        display: none;
    }

    .linkmenu {
        display: block;

    }

    .linkmenu button {
        width: 100%;
        border: 1px solid #000;
        border-radius: 5px;
        padding: 2px 10px;
        background: none;
    }

}

@media only screen and (max-width: 481px) {
    .search {
        width: auto;
    }

    .header_right {
        width: 20%;
    }

}